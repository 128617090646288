/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
/*
 * Sun-Sky  2024/1/5 下午5:00
 */

import fetch from 'common/js/fetch';
import { getRandomInt } from 'common/js/util';
import { Api } from '@/api/api';
import { getPayOpenid } from '@/common/js/getPayOpenid';
import { wxLoginOauth } from '@/common/js/wxLoginOauth';
import { distributePagesNotify } from '@/common/js/dataCenter';
import store from "@/store";

// 访问错误或者没有配置租户时，default data
let data = {
  base: {
    'title': '',
    'meta': '',
    'favicon': ''
  },
  'baseInfoComponent': {
    'companyLogoUrl': '',
    'companyName': '',
    'companyStatus': '',
    // 百度统计代码
    'footerDecline': '',
    'footerRecord': ''
  },
  'skinComponent': '',
  'listViewComponent': {
    'listClassViewType': '',
    'listRecommendViewType': ''
  },
  'visibleComponent': {
    'index': true,
    'register': true
  },
  'paperTitleValue': '论文',
  'caseStoryTitleValue': '病案故事',
  'question3TitleValue': '调研问卷3.0'
};
const user = {
  state: {
    videoPause: '',
    // 暂停视频播放
    businessTrain: false,
    businessClass: false,
    businessOnlineOpenClass: false,
    busAcademicConference: false,
    busInteractionOpenClass: false,
    busCaseCollection: false,
    businteractiveCloudClassroom: false,
    busAirClassroomPro: false,
    // 空中课堂pro
    busInteractiveClassroomPro: false,
    // 互动课堂pro
    businessAll: [],
    // 租户持有的所有业务
    isApplyJoin: false,
    // 是否有申请系列页面
    isTask: false,
    // 是否有任务系列页面
    iisAppsApp: false,
    // 是不是app内显示
    websiteTitle: '',
    service: 1,
    // 业务类型
    companyLogoUrl: '',
    companyStatus: '',
    // 百度统计代码
    companyIsTask: 0,
    // 是否开通任务系统
    companyName: '',
    companyId: '',
    caseTitleShow: 0,
    caseTitleValue: '',
    paperTitleValue: '',
    // 配置论文征集动态title
    caseStoryTitleValue: '',
    // 配置病历故事动态title
    questionTitleShow: 0,
    questionTitleValue: '',
    // 调查问卷自定义标题
    footerDecline: '',
    footerRecord: '',
    enterpriseRecommend: '',
    airClass: '',
    skinComponent: '',
    obsUrl: '',
    wechatShare: true,
    // 是否有微信分享
    wxConfig: {},
    indexLiveList: [],
    companyConfig: {},
    // 租户配置信息
    indexVisible: null,
    // 首页默认为不可见
    registerVisible: false,
    // 注册按钮或者模块 默认为不可见
    isAppDownload: false,
    // 是否展示下载APP
    businessIntegral: false,
    // 是否展示积分
    activityDrawGame: false,
    // 抽奖活动
    activityDrawGameV2: false,
    // 抽奖活动v2
    activityExamPaper: false,
    // 线上答题活动
    activityCompetition: false,
    // 知识竞赛活动
    activityInteractiveCase: false,
    // 互动病例活动
    activityCommunication: false,
    // 互动交流活动
    activitySign: false,
    // 线上签约活动
    microWebsite: false,
    // 微官网
    activityQuestionnaire: false,
    // 问卷调查活动
    activityQuestionnaireV3: false,
    // 问卷调查活动3.0
    activityCaseVote: false,
    // 病例投票活动
    activityVote: false,
    // 投票活动
    activityVoteV3: false,
    // 投票活动V3
    coursewareUpload: false,
    // 课件上传活动
    agreementList: '',
    // 隐私协议列表
    newWebsite: '',
    // 新旧网站
    columnAlias: '',
    // 精彩活动别名
    newActNav: {},
    // 新网站当前导航
    activityVotingComp: false,
    // 投票评比
    paperSubmission: false,
    // 论文征集
    caseStory: false,
    // 病历故事
    topicPk: false,
    // 话题PK
    coinEncrypt: 0,
    enableCompleteInfo: false,
    // 是否启用完善信息（后台配置）
    inviteCoverImg: '' // 邀请海报
  },

  mutations: {
    SET_WECHAT_SHARE: (state, wechatShare) => {
      state.wechatShare = wechatShare;
    },
    SET_COMPANY_CONFIG: (state, companyConfig) => {
      state.companyConfig = companyConfig;
    },
    SET_INDEX_VISIBLE: (state, indexVisible) => {
      state.indexVisible = indexVisible;
    },
    SET_REGISTER_VISIBLE: (state, registerVisible) => {
      state.registerVisible = registerVisible;
    },
    SET_INDEX_LIST: (state, indexLiveList) => {
      state.indexLiveList = indexLiveList;
    },
    SET_LOGO_URL: (state, companyLogoUrl) => {
      state.companyLogoUrl = companyLogoUrl;
    },
    SET_PAPERSUBMISSION: (state, paperSubmission) => {
      state.paperSubmission = paperSubmission;
    },
    SET_ACTIVITY_CASESTORY: (state, caseStory) => {
      // 判断是否有病历故事
      state.caseStory = caseStory;
    },
    SET_ACTIVITY_TOPICPK: (state, topicPk) => {
      // 判断是否有话题PK
      state.topicPk = topicPk;
    },
    SET_COMPANY_STATUS: (state, companyStatus) => {
      state.companyStatus = companyStatus;
    },
    SET_IS_TASK: (state, companyIsTask) => {
      state.companyIsTask = companyIsTask;
    },
    SET_SERVICE: (state, service) => {
      state.service = service;
    },
    SET_COMPANY_NAME: (state, companyName) => {
      state.companyName = companyName;
    },
    SET_COMPANY_ID: (state, companyId) => {
      state.companyId = companyId;
    },
    SET_CASETITLESHOW: (state, caseTitleShow) => {
      state.caseTitleShow = caseTitleShow;
    },
    SET_CASETITLEVALUE: (state, caseTitleValue) => {
      state.caseTitleValue = caseTitleValue;
    },
    SET_PAPER_TITLE_VALUE: (state, paperTitleValue) => {
      // 配置论文征集动态title
      state.paperTitleValue = paperTitleValue;
    },
    SET_CASE_STORY_TITLE_VALUE: (state, caseStoryTitleValue) => {
      // 配置病历故事动态title
      state.caseStoryTitleValue = caseStoryTitleValue;
    },
    SET_QUESTIONTITLESHOW: (state, questionTitleShow) => {
      state.questionTitleShow = questionTitleShow;
    },
    SET_QUESTIONTITLEVALUE: (state, questionTitleValue) => {
      state.questionTitleValue = questionTitleValue;
    },
    SET_WEBSITE_TITLE: (state, title) => {
      state.websiteTitle = title;
    },
    SET_FOOTER_DECLINE: (state, footerDecline) => {
      state.footerDecline = footerDecline;
    },
    SET_FOOTER_RECORD: (state, footerRecord) => {
      state.footerRecord = footerRecord;
    },
    SET_ENTERPRISE_RECOMMEND: (state, enterpriseRecommend) => {
      state.enterpriseRecommend = enterpriseRecommend;
    },
    SET_AIR_CLASS: (state, airClass) => {
      state.airClass = airClass;
    },
    SET_SKIN: (state, skinComponent) => {
      state.skinComponent = skinComponent;
    },
    SET_OBS_URL: (state, obsUrl) => {
      state.obsUrl = obsUrl;
    },
    SET_BUSINESS_ALL: (state, businessAll) => {
      // 租户持有的所有业务
      state.businessAll = businessAll;
    },
    SET_BS_TRAIN: (state, businessTrain) => {
      // 判断是否有空中课堂业务
      state.businessTrain = businessTrain;
    },
    SET_BS_CLASS: (state, businessClass) => {
      // 判断是否有店员培训业务
      state.businessClass = businessClass;
    },
    SET_BS_ONLINE_OPEN_CLASS: (state, businessOnlineOpenClass) => {
      // 判断是否有线上公开课业务
      state.businessOnlineOpenClass = businessOnlineOpenClass;
    },
    SET_BS_ACADEMIC_CONFERENCE: (state, busAcademicConference) => {
      // 判断是否有学术会议
      state.busAcademicConference = busAcademicConference;
    },
    SET_BS_INTER_ACTION_OPEN_CLASS: (state, busInteractionOpenClass) => {
      // 判断是否有互动公开课业务
      state.busInteractionOpenClass = busInteractionOpenClass;
    },
    SET_BS_BS_CASECOLLECTION: (state, busCaseCollection) => {
      // 判断是否有病例征集
      state.busCaseCollection = busCaseCollection;
    },
    SET_BS_BUSINTERACTIVECLOUDCLASSROOM: (state, businteractiveCloudClassroom) => {
      // 判断是否有互动课堂
      state.businteractiveCloudClassroom = businteractiveCloudClassroom;
    },
    SET_BS_BUS_AIR_CLASSROOM_PRO: (state, busAirClassroomPro) => {
      // 判断是否有空中课堂Pro
      state.busAirClassroomPro = busAirClassroomPro;
    },
    SET_BS_BUS_INTERACTIVE_CLASSROOM_PRO: (state, busInteractiveClassroomPro) => {
      // 判断是否有互动课堂Pro
      state.busInteractiveClassroomPro = busInteractiveClassroomPro;
    },
    SET_ISAPP: (state, isApp) => {
      // 判断是否有申请系列页面
      state.isApp = isApp;
    },
    SET_APPLYJOIN: (state, isApplyJoin) => {
      // 判断是否有申请系列页面
      state.isApplyJoin = isApplyJoin;
    },
    SET_WXCONFIG: (state, wxConfig) => {
      // 判断是否有申请系列页面
      state.wxConfig = wxConfig;
    },
    SET_ISAPPDOWNLOAD: (state, isDownload) => {
      // 判断是否有APP下载页面
      state.isAppDownload = isDownload;
    },
    SET_BS_integral: (state, isi) => {
      // 判断是否有积分页面（平台积分）
      state.businessIntegral = isi;
    },
    SET_ACTIVITY_DRAWGAME: (state, activityDrawGame) => {
      // 判断是否有抽奖活动
      state.activityDrawGame = activityDrawGame;
    },
    SET_ACTIVITY_DRAWGAME_V2: (state, activityDrawGameV2) => {
      // 判断是否有抽奖活动v2
      state.activityDrawGameV2 = activityDrawGameV2;
    },
    SET_ACTIVITY_EXAMPAPER: (state, activityExamPaper) => {
      // 判断是否有线上答题活动
      state.activityExamPaper = activityExamPaper;
    },
    SET_ACTIVITY_COMPETITION: (state, activityCompetition) => {
      // 判断是否有知识竞赛活动
      state.activityCompetition = activityCompetition;
    },
    SET_ACTIVITY_INTERACTIVECASE: (state, activityInteractiveCase) => {
      // 判断是否有互动病例活动
      state.activityInteractiveCase = activityInteractiveCase;
    },
    SET_ACTIVITY_COMMUNICATION: (state, activityCommunication) => {
      // 判断是否有互动交流活动
      state.activityCommunication = activityCommunication;
    },
    SET_ACTIVITY_SIGN: (state, activitySign) => {
      // 判断是否有合同签约活动
      state.activitySign = activitySign;
    },
    SET_MICRO_WEBSITE: (state, microWebsite) => {
      // 判断是否有微官网
      state.microWebsite = microWebsite;
    },
    SET_ACTIVITY_QUESTIONNAIRE: (state, Questionnaire) => {
      // 判断是否有问卷调查活动
      state.activityQuestionnaire = Questionnaire;
    },
    SET_ACTIVITY_QUESTIONNAIREV3: (state, Questionnairev3) => {
      // 判断是否有问卷调查活动3.0
      state.activityQuestionnaireV3 = Questionnairev3;
    },
    SET_ACTIVITY_CASEVOTE: (state, activityCaseVote) => {
      // 判断是否有病例投票
      state.activityCaseVote = activityCaseVote;
    },
    SET_ACTIVITY_VOTE: (state, activityVote) => {
      // 判断是否有投票活动
      state.activityVote = activityVote;
    },
    SET_ACTIVITY_VOTEV3: (state, activityVote) => {
      // 判断是否有投票活动V3
      state.activityVoteV3 = activityVote;
    },
    SET_ACTIVITY_VOTINGCOMP: (state, activityVotingComp) => {
      // 判断是否有投票活动V3
      state.activityVotingComp = activityVotingComp;
    },
    SET_ACTIVITY_COURSEWAREUPLOAD: (state, coursewareUpload) => {
      // 判断是否有课件上传活动
      state.coursewareUpload = coursewareUpload;
    },
    SET_AGREEMENT_LIST: (state, agreementList) => {
      // 隐私协议内容
      state.agreementList = [];
      for (let item of agreementList) {
        if (!item.isHide) {
          state.agreementList.push(item);
        }
      }
    },
    SET_COLUMN_ALIAS: (state, columnAlias) => {
      // 精彩栏目别名
      state.columnAlias = columnAlias;
    },
    SET_NEWWEBSITE: (state, obj) => {
      // 新旧网站
      state.newWebsite = obj;
    },
    SET_NEWACTNAV: (state, val) => {
      // 新旧网站
      state.newActNav = val;
    },
    SET_COINENCRYPT: (state, val) => {
      //积分排行榜名字是否脱敏
      state.coinEncrypt = val;
    },
    SET_ENABLE_COMPLETE_INFO: (state, val) => {
      state.enableCompleteInfo = !!val;
    },
    SET_INVITE_COVER_IMG: (state, val) => {
      state.inviteCoverImg = val;
    }
  },
  actions: {
    // 配置设置
    async SetConfig({
      commit
    }) {
      const response = await fetch.post(Api.common.config, {}).catch(() => {
        window.getConfigStatus = true;
        commit('SET_REGISTER_VISIBLE', false);
        commit('SET_INDEX_VISIBLE', false);
        commit('SET_LOGO_URL', data.baseInfoComponent.companyLogoUrl);
        commit('SET_IS_TASK', data.baseInfoComponent.companyIsTask);
        commit('SET_COMPANY_NAME', data.baseInfoComponent.companyName);
        commit('SET_FOOTER_DECLINE', data.baseInfoComponent.footerDecline);
        commit('SET_FOOTER_RECORD', data.baseInfoComponent.footerRecord);
        commit('SET_COMPANY_ID', data.baseInfoComponent.companyId);
        commit('SET_CASETITLESHOW', response.data.caseTitleShow);
        if (response.data.caseTitleShow) {
          commit('SET_CASETITLEVALUE', response.data.caseTitleValue);
        } else {
          commit('SET_CASETITLEVALUE', '医学研究');
        }
        commit('SET_PAPER_TITLE_VALUE', response.data['thesisTitleValue']);
        commit('SET_QUESTIONTITLESHOW', response.data.questionTitleShow);
        commit('SET_QUESTIONTITLEVALUE', response.data.questionTitleValue);
        commit('SET_SKIN', data.skinComponent);
        commit('SET_ENTERPRISE_RECOMMEND', data.listViewComponent.listRecommendViewType);
        commit('SET_AIR_CLASS', data.listViewComponent.listClassViewType);
        let link = document.head.querySelector('link');
        let i = document.getElementsByTagName('meta');
        document.title = data.base.title;
        link.href = data.baseInfoComponent.companyLogoUrl;
        i[0]['content'] = data.base.meta;
      });
      let query = window.location.href.split('?')[1];
      if (!query || query.indexOf('wxOauth=') < 0) {
        await wxLoginOauth(response.data);
        await getPayOpenid(response.data);
      }
      data.base.title = response.data.platomName === undefined ? '暂未配置' : response.data.platomName;
      window.getConfigStatus = true;
      data.baseInfoComponent.companyName = response.data.platomName;
      data.baseInfoComponent.companyId = response.data.id;
      data.baseInfoComponent.companyLogoUrl = response.data.logoUrl;
      data.baseInfoComponent.companyStatus = response.data.status;
      data.baseInfoComponent.footerRecord = response.data.icp;
      data.baseInfoComponent.companyIsTask = response.data.task;
      if (response.data.indexVisible === 0) {
        commit('SET_INDEX_VISIBLE', false);
      } else {
        commit('SET_INDEX_VISIBLE', true);
      }
      if (response.data.registerVisible === 0) {
        commit('SET_REGISTER_VISIBLE', false);
      } else {
        commit('SET_REGISTER_VISIBLE', true);
      }
      if (response.data.app === 1) {
        commit('SET_ISAPPDOWNLOAD', true);
      }
      if (response.data.agreename && !response.data.agreementList) {
        commit('SET_AGREEMENT_LIST', {
          name: response.data.agreename,
          content: response.data.agreecontent
        });
      }
      if (response.data.agreementList) {
        commit('SET_AGREEMENT_LIST', response.data.agreementList);
      }
      commit('SET_INVITE_COVER_IMG', response.data.inviteImg);
      commit('SET_ENABLE_COMPLETE_INFO', Number(response.data.perfectUserInfo) || 0);
      commit('SET_COLUMN_ALIAS', response.data.columnAlias);
      commit('SET_COMPANY_CONFIG', response.data);
      commit('SET_LOGO_URL', data.baseInfoComponent.companyLogoUrl);
      commit('SET_COMPANY_STATUS', data.baseInfoComponent.companyStatus);
      commit('SET_IS_TASK', data.baseInfoComponent.companyIsTask);
      commit('SET_WEBSITE_TITLE', data.base.title);
      localStorage.setItem('SET_WEBSITE_TITLE', data.base.title);
      commit('SET_COMPANY_NAME', data.baseInfoComponent.companyName);
      commit('SET_COMPANY_ID', data.baseInfoComponent.companyId);
      commit('SET_CASETITLESHOW', response.data.caseTitleShow);
      // 课件上传配置子应用消息通知（启用页数审核、最小页数）
      distributePagesNotify({
        coursewareAudit: response.data.coursewareAudit,
        coursewareAuditPages: response.data.coursewareAuditPages
      });
      if (response.data.caseTitleShow) {
        commit('SET_CASETITLEVALUE', response.data.caseTitleValue);
      } else {
        commit('SET_CASETITLEVALUE', '医学研究');
      }
      commit('SET_PAPER_TITLE_VALUE', response.data['thesisTitleValue']);
      commit('SET_QUESTIONTITLESHOW', response.data.questionTitleShow);
      if (data.questionTitleShow) {
        commit('SET_QUESTIONTITLEVALUE', `${response.data.questionTitleValue}`);
      } else {
        commit('SET_QUESTIONTITLEVALUE', '问卷');
      }
      if (response.data.caseStoryTitleShow) {
        commit('SET_CASE_STORY_TITLE_VALUE', response.data.caseStoryTitleValue);
      } else {
        commit('SET_CASE_STORY_TITLE_VALUE', '病案故事');
      }
      commit('SET_FOOTER_DECLINE', data.baseInfoComponent.footerDecline);
      commit('SET_FOOTER_RECORD', data.baseInfoComponent.footerRecord);
      commit('SET_SKIN', data.skinComponent);
      commit('SET_ENTERPRISE_RECOMMEND', data.listViewComponent.listRecommendViewType);
      commit('SET_AIR_CLASS', data.listViewComponent.listClassViewType);
      commit('SET_COINENCRYPT', response.data.coinEncrypt);
      if (response.data.webversion === 1) {
        commit('SET_NEWWEBSITE', true);
      }
      document.title = data.base.title;
      await store.dispatch('setNavBanner');
      return response;
    },
    // 配置service设置
    async SetService({
      commit
    }) {
      const response = await fetch.post(Api.common.getService, {}).catch(() => {
        commit('SET_SERVICE', 1);
        window.serviceType = 'train/';
      });
      response.data.length === 0 ? commit('SET_SERVICE', 1) : commit('SET_SERVICE', parseInt(response.data[0].businessId));
      window.serviceType = response.data[0].businessId === '1' ? 'train/' : 'classroom/';
      const info = response.data;
      commit('SET_BUSINESS_ALL', info);
      for (let i = 0; i < info.length; i++) {
        if (info[i].businessId === '1') {
          commit('SET_BS_TRAIN', true);
          commit('SET_APPLYJOIN', true);
        } else if (info[i].businessId === '2') {
          commit('SET_BS_CLASS', true);
          commit('SET_APPLYJOIN', true);
        } else if (info[i].businessId === '3') {
          commit('SET_BS_INTER_ACTION_OPEN_CLASS', true);
          commit('SET_APPLYJOIN', true);
        } else if (info[i].businessId === '4') {
          commit('SET_BS_ONLINE_OPEN_CLASS', true);
          commit('SET_APPLYJOIN', true);
        } else if (info[i].businessId === '5') {
          commit('SET_BS_ACADEMIC_CONFERENCE', true);
          commit('SET_APPLYJOIN', true);
        } else if (info[i].businessId === '6') {
          commit('SET_BS_integral', true);
          commit('SET_APPLYJOIN', true);
        } else if (info[i].businessId === '8') {
          commit('SET_BS_BS_CASECOLLECTION', true);
          commit('SET_APPLYJOIN', true);
        } else if (info[i].businessId === '9') {
          commit('SET_BS_BUSINTERACTIVECLOUDCLASSROOM', true);
          commit('SET_APPLYJOIN', true);
        } else if (info[i].businessId === '10') {
          // 空中课堂pro
          commit('SET_BS_BUS_AIR_CLASSROOM_PRO', true);
          commit('SET_APPLYJOIN', true);
        } else if (info[i].businessId === '11') {
          // 互动课堂pro
          commit('SET_BS_BUS_INTERACTIVE_CLASSROOM_PRO', true);
          commit('SET_APPLYJOIN', true);
        }
      }
    },
    SetSkin({
      commit,
      state
    }) {
      return new Promise(resolve => {
        let ary = ['default', 'primary'];
        let val = ary[getRandomInt(0, 2)];
        commit('SET_SKIN', val);
        resolve();
      });
    },
    ObsConfig({
      commit
    }) {
      fetch.post(Api.service.getObsFile).then(response => {
        if (response.code === 0) {
          if (response.data.status === 1) {
            commit('SET_OBS_URL', response.data.url);
          }
        }
      }).catch();
    },
    GetNewIndexList({
      commit
    }, serviceType) {
      fetch.post(Api.service.queryLiveList[0] + serviceType + Api.service.queryLiveList[1], {
        pageSize: 15,
        pageNum: 1
      }).then(response => {
        let data = response.data;
        commit('SET_INDEX_LIST', data.data);
      }).catch();
    },
    SetIsApp({
      commit
    }, isApp) {
      commit('SET_ISAPP', isApp);
    },
    SetWxconfig({
      commit
    }, config) {
      commit('SET_WXCONFIG', config);
    },
    async BusActivityConfig({
      commit
    }) {
      const response = await fetch.post(Api.action.getActivity, {}).catch(() => {});
      if (response.code === 0) {
        if (response.data.length > 0) {
          response.data.forEach(item => {
            switch (item.actionDetailId) {
              case 1:
                commit('SET_ACTIVITY_DRAWGAME', true);
                break;
              case 2:
                commit('SET_ACTIVITY_EXAMPAPER', true);
                break;
              case 3:
                commit('SET_ACTIVITY_SIGN', true);
                break;
              case 4:
                commit('SET_ACTIVITY_COMPETITION', true);
                break;
              case 5:
                commit('SET_ACTIVITY_QUESTIONNAIRE', true);
                break;
              case 6:
                commit('SET_MICRO_WEBSITE', true);
                break;
              case 7:
                commit('SET_ACTIVITY_CASEVOTE', true);
                break;
              case 8:
                commit('SET_ACTIVITY_VOTE', true);
                break;
              case 9:
                commit('SET_ACTIVITY_DRAWGAME_V2', true);
                break;
              case 10:
                commit('SET_ACTIVITY_VOTEV3', true);
                break;
              case 12:
                commit('SET_ACTIVITY_COURSEWAREUPLOAD', true);
                break;
              case 13:
                commit('SET_ACTIVITY_QUESTIONNAIREV3', true);
                break;
              case 14:
                commit('SET_ACTIVITY_INTERACTIVECASE', true);
                break;
              case 15:
                commit('SET_ACTIVITY_COMMUNICATION', true);
                break;
              case 17:
                commit('SET_ACTIVITY_VOTINGCOMP', true);
                break;
              case 20:
                commit('SET_PAPERSUBMISSION', true);
                break;
              case 22:
                commit('SET_ACTIVITY_TOPICPK', true);
                break;
              case 23:
                commit('SET_ACTIVITY_CASESTORY', true);
                break;
            }
          });
        }
      }
      return response;
    }
  }
};
export default user;