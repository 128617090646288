/* this file is transformed by vux-loader */
/* eslint-disable */
/*
 * Sun-Sky  2024/5/27 上午10:32
 */

let host = 'https://api.cmalive.cn/'; // 线上API地址
const href = window.location.href;
if (href.indexOf('localhost') > 0 || window.location.port.indexOf('600') >= 0) {
  host = ''; // 本地调试走代理
} else if (href.indexOf('.test.') > 0) {
  host = 'https://30.100api.cn/';
} else if (href.indexOf('.100live.') >= 0 || href.indexOf('saas-test.') >= 0) {
  host = 'https://liveapi.cn/';
}
let isLocal = href.indexOf('localhost') > 0 || href.indexOf(':8090') > 0;
let socketDomain = host;
if (isLocal) {
  socketDomain = 'https://30.100api.cn/';
}
const userHost = `${host}yb-user/`;
const userDjbHost = `${host}djb/yb-user-api/`;
const socketHost = `${socketDomain}yb-websocket/`;
const payHost = `${host}yb-pay/`;
const orderHost = `${host}yb-order/`;
const cmsHost = `${host}yb-cms/`;
const vmsHost = `${host}yb-vms/`;
const businessHost = `${host}yb-business/`;
const actionHost = `${host}yb-action/`;
const thirdHost = `${host}yb-thrid/`;
const businessApiHost = `${host}djb/yb-business-api/`;
const businessApiHostNtk = `${host}djb/yb-business-api-ntk/`;
const userApiHost = `${host}djb/yb-user-api/`;
const userApiHostNtk = `${host}djb/yb-user-api-ntk/`;
const djbVmsHost = `${host}djb/yb-vms-api/`;
const djbCommentHost = `${host}djb/yb-comment-api/`;
const djbCommentNtkHost = `${host}djb/yb-comment-api-ntk/`;
const djbQuestionHost = `${host}djb/yb-question-api/`;
const djbQuestionNtkHost = `${host}djb/yb-question-api-ntk/`;
const interactiveCaseHost = `${host}djb/yb-case-api/`;
const interactiveGameHost = `${host}djb/yb-game-api/`;
const djbSignHost = `${host}djb/yb-sign-api/`;
const djbCMSNtkHost = `${host}djb/yb-cms-api-ntk/`;
const djbCMSHost = `${host}djb/yb-cms-api/`;
const qaHost = `${host}djb/yb-qa-api/`;
const qaHostNtk = `${host}djb/yb-qa-api-ntk/`;
const djbInvitationHostNtk = `${host}djb/yb-invitation-api-ntk/`;
const djbVoteHost = `${host}djb/yb-vote-api/`;
const djbVoteNtkHost = `${host}djb/yb-vote-api-ntk/`;
const strategyApiHost = `${host}djb/yb-strategy-api/`;
const djbTaskHost = `${host}djb/yb-task-api/`;
const djbresourceHost = `${host}djb/yb-resource-api-ntk/`;
const djbStrategyNtkHost = `${host}djb/yb-strategy-api-ntk/`;
const djbStatisticsHost = `${host}djb/yb-statistics-api/`;
const djblotteryHost = `${host}djb/yb-lottery-api/`;
const djbpayHost = `${host}djb/yb-pay-api/`;
const djbpayNtkHost = `${host}djb/yb-pay-api-ntk/`;
const djbVotingV3 = `${host}djb/yb-saas-vote-api/`;
const djbDocumentHost = `${host}djb/yb-document-api/`;
const djbClassProHost = `${host}djb/yb-business-pro-api`;
const djbQaV3 = `${host}djb/yb-qav3-api/`;
const djbCommunicateHost = `${host}djb/yb-communication-api/`;
const djbNotifyHost = `${host}djb/yb-notify-api/`;
const djbStarApiHost = `${host}djb/yb-star-api/`;
const djbVotingHost = `${host}djb/yb-voting-api/`;
const paperSubmissionHost = `${host}djb/yb-thesis-api/`;
const caseDataBase = `${host}djb/yb-information-api/`;
const topicPkHost = `${host}djb/yb-topic-pk-api/`;
const caseStoryHost = `${host}djb/yb-case-story-api/`;
export var Api = {
  socket: {
    server: `${socketHost}stompServer`
  },
  wechat: {
    getOauthAppId: `${userApiHostNtk}wx/oauth/appid`,
    // 获取关联公众号appid   中台登录
    getOfficialaccountSubscribe: `${businessApiHost}video/officialaccount/subscribe`,
    // 是否关注公众号
    getUrlLink: `${businessApiHostNtk}video/applet/urllink` // 获取小程序 URL Link
  },

  questionnaireV3: {
    saveApplyInfo: `${djbQaV3}qaUserApply/save/apply/info`,
    // 申请加入提交
    get: `${djbQaV3}qaActiveInfo/detail/`,
    // 通过id 获取问卷信息 编辑回显
    apply: `${djbQaV3}qaUserApply/judgeUser`,
    // 申请加入-是否需要申请
    importUserInfo: `${djbQaV3}qaUserInfo/get/userInfo`,
    // 申请加入-获取用户信息 「调研问卷v3获取导入信息接口」
    activeUserApply: `${djbQaV3}qaUserApply/activeUserApply`,
    // 申请加入-申请
    myApplyList: `${djbQaV3}qaUserApply/myApplyList`,
    // 问卷调查3.0-我的申请
    myqaListV3: `${djbQaV3}qaSubmitData/userJoin/pageList`,
    // 问卷调查3.0-我的-活动列表
    mySubsV3: `${djbQaV3}qaSubmitData/userJoinData/pageList`,
    // 问卷调查3.0-我的-我的提交记录
    qaCompapplyList: `${djbQaV3}qaActiveInfo/created/mine` // 申请管理
  },

  // 定制化需求
  customization: {
    getJingZhuToken: `${userApiHost}third/jingzhu/regist/` // 晶珠 积分商城跳转小程序获取token
  },

  doc: {
    download: `${djbDocumentHost}front/download` // 资料中心-文件下载
  },

  app: {
    getList: `${cmsHost}appversion/list` // APP 获取下载列表
  },

  dataCenter: {
    getPlayId: `${djbStatisticsHost}uniq/get/playId`,
    // 获取唯一播放 id
    push: `${djbStatisticsHost}statistics/report` // 统计访问记录
  },

  userApi: {
    validateIdCard: `${djbSignHost}user/validate/idcard`,
    // 查询身份证验证状态
    saveIdCard: `${djbSignHost}user/validate/idcard/save`,
    // 保存身份证信息
    getUserApi: `${userApiHost}userinfo/getInfoById`,
    // 申请加入时获取用户信息
    importUserInfo: `${qaHost}qa/user/import/get`,
    // 申请加入时获取用户信息 「调研问卷获取导入信息接口」
    upUser: `${userApiHost}userinfo/update`,
    // code: 319 状态完善用户信息
    getOffice: `${userApiHost}companyOffice/getAllVisibleCompanyOffice`,
    // 获取租户下可见科室
    getUserIdentityList: `${userApiHost}companytitle/get`,
    // 获取租户身份列表 or 通过租户id获取租户身份列表 GET请求/{租户id}
    getTitleAll: `${userApiHost}title/all`,
    // 获取某个租户下配置的所有职称
    getHospital: `${userApiHost}hospital/list`,
    // 获取大数据查询医院
    getListByHospitalName: `${userApiHost}hospital/getListByHospitalName`,
    // 查询匹配后的医院列表
    hospitalBigdataSave: `${userApiHost}hospital/bigdata/save`,
    // 大数据同步医院
    wxgetsubscribe: `${userApiHost}wx/subscribe/`,
    // 获取用户订阅信息 {openId}/{companyId}
    wxgetqr: `${userApiHost}wx/sceneQrcode/Create`,
    // 获取带参数的二维码
    wxgetopenid: `${userApiHost}wx/getOpenId/`,
    // 获取openId
    wxgetSetting: `${userApiHost}/wx/getCompanyWxSetting` // 获取微信公众号配置
  },

  integral: {
    goodsList: `${orderHost}order/pointMall/front/goodsList`,
    //   积分-商品首页-商品列表
    categoryList: `${orderHost}order/pointMall/front/category/list`,
    //   积分-商品首页-品类列表
    goodsInfo: `${orderHost}order/pointMall/front/goodsInfo`,
    //   积分-商品详情
    buy: `${orderHost}order/pointMall/front/buy`,
    //   积分-积分兑换-商品兑换
    validateCode: `${orderHost}sms/user/sendcode`,
    //   积分-积分兑换-短信验证
    pointSurplus: `${orderHost}order/pointMall/front/pointSurplus`,
    //   积分-积分兑换-剩余积分
    addressList: `${orderHost}order/pointMall/front/addressList`,
    //   积分-收货地址-地址列表
    addressSave: `${orderHost}order/pointMall/front/address/save`,
    //   积分-收货地址-地址新增
    addressUpdate: `${orderHost}order/pointMall/front/address/update`,
    //   积分-收货地址-地址编辑
    addressDelete: `${orderHost}order/pointMall/front/address/delete`,
    //   积分-收货地址-地址删除
    coinMy: `${orderHost}coin/`,
    //   积分-平台积分-积分剩余 GET
    coinList: `${orderHost}coin/list`,
    //   积分-平台积分-积分列表
    coinSum: `${orderHost}coin/pointSum`,
    //   积分-平台积分-积分合计
    coinDetail: `${orderHost}coin/detail/`,
    //   积分-平台积分-积分详情 GET
    pointMy: `${orderHost}point/`,
    //   积分-医百积分-积分剩余 GET
    pointList: `${orderHost}point/list`,
    //   积分-医百积分-积分列表
    pointSum: `${orderHost}point/pointSum`,
    //   积分-医百积分-积分合计
    pointDetail: `${orderHost}point/detail/` //   积分-医百积分-积分详情 GET
  },

  game: {
    lotteryGame: `${actionHost}lotteryGame/lotteryGame`,
    // 抽奖 post
    getlotteryGame: `${actionHost}lotteryGame/detail/`,
    // 获取抽奖详情
    getWinList: `${actionHost}lGPersonWin/getWinList`,
    // 获取中奖记录
    setAddress: `${actionHost}lGPersonWin/setAddress`,
    // 填写收货地址
    getUserPrizes: `${actionHost}lGPersonWin/getUserPrizes`,
    // 获取我的中奖记录
    getGameId: `${actionHost}lotteryGame/getByBusId/`,
    // 获取资源内游戏ID
    hasGoGame: `${actionHost}lotteryGame/hasQualification/`,
    // 查询游戏进入资格
    getPrizes: `${actionHost}lGPrize/get/`,
    // 获取奖品详情
    // ---------new 抽奖-----------
    getGames: `${djblotteryHost}lottery/games`,
    // 查询抽奖结果
    getCount: `${djblotteryHost}lottery/user/count/`,
    // 查询剩余抽奖次数
    getActivityDetail: `${djblotteryHost}lottery/manage/getActivityDetail/`,
    // 查询活动详情
    getWinnerRecords: `${djblotteryHost}lottery/user/getWinnerRecords/`,
    // 查询中奖记录
    getUserPrize: `${djblotteryHost}lottery/getUserPrize/`,
    // 我的奖品回显
    getUserAddress: `${djblotteryHost}lottery/getUserAddress/`,
    // /user/address
    myLottery: `${djblotteryHost}lottery/my-lottery`,
    // 抽奖活动v2——个人抽奖记录
    setNewAddress: `${djblotteryHost}lottery/user/address`,
    // 用户地址信息填写
    receiveRedPacket: `${djblotteryHost}lottery/redPacket/receive` // 领取现金红包
  },

  voting: {
    getVoting: `${actionHost}active/voting/active/previewVoting`,
    // 投票活动详情页
    getOption: `${actionHost}active/voting/active/getOption`,
    // 投票活动 选项详情页
    doVote: `${actionHost}active/voting/doVote`,
    // 投票活动 投票
    case: {
      // 病例投票
      voteinfo: `${djbVoteNtkHost}vote/ranking/voteinfo/ntk`,
      // 病例投票 - 投票活动详情
      grouplist: `${djbVoteNtkHost}vote/option/group/list/ntk`,
      // 病例投票 - 分组列表
      votelist: `${djbVoteNtkHost}vote/ranking/list/ntk`,
      // 病例投票 - 投票项列表
      getMyScore: `${djbVoteHost}vote/ranking/votednum`,
      // 病例投票 - 获取我的已评分数
      votecommit: `${djbVoteHost}vote/commit`,
      // 病例投票 - 投票投票/评分
      apply: `${djbVoteHost}vote/apply/join`,
      // 病例投票 - 申请管理 - 申请
      applyList: `${djbVoteHost}vote/apply/list`,
      // 病例投票 - 申请管理 - 列表
      applyMy: `${djbVoteHost}vote/apply/my`,
      // 病例投票 - 申请管理 - 我的申请
      applyDetail: `${djbVoteHost}vote/apply/detail`,
      // 病例投票 - 申请管理 - 申请详情
      applyAudit: `${djbVoteHost}vote/apply/audit` // 病例投票 - 申请管理 - 审核
    }
  },

  votingV3: {
    visit: `${djbVotingV3}vote/application/vote/visit`,
    // 投票活动-数据统计
    getInfo: `${djbVotingV3}vote/application/queryBasic`,
    // 投票活动-活动详情
    getList: `${djbVotingV3}vote/application/queryCompetition`,
    // 投票活动-选项列表
    getRankte: `${djbVotingV3}vote/application/queryRank`,
    // 投票活动-排行榜
    getDetail: `${djbVotingV3}vote/application/query/result/detail`,
    // 投票活动-选项详情页
    doVote: `${djbVotingV3}vote/application/vote`,
    // 投票活动-投票
    apply: `${djbVotingV3}vote/application/apply/join`,
    // 投票活动 - 申请管理 - 申请
    applyList: `${djbVotingV3}vote/application/apply/list`,
    // 投票活动 - 申请管理 - 列表
    applyMy: `${djbVotingV3}vote/application/apply/my/list`,
    // 投票活动 - 申请管理 - 我的申请
    applyDetail: `${djbVotingV3}vote/application/apply/detail/`,
    // 投票活动 - 申请管理 - 申请详情
    applyAudit: `${djbVotingV3}vote/application/apply/operate` // 投票活动 - 申请管理 - 审核
  },

  votingComp: {
    votingCompSecondList: `${djbVotingHost}voting/front/pc/myActivityList/myVotingWorkList`,
    // 上传作品/参与投票
    votingCompapplyList: `${djbVotingHost}voting/front/created/mine`,
    // 申请管理
    votingCompFrontapplyList: `${djbVotingHost}voting/front/apply/mine`,
    // 我的申请列表
    saveApplyInfo: `${djbVotingHost}votingWork/front/save/apply/info`,
    // 申请加入
    votingMyList: `${djbVotingHost}voting/front/pc/myActivityList` // 投票评比我的活动
  },

  case: {
    getRecentDraft: `${businessHost}v3/case/fill/recent/draft?caseCollectId=`,
    // 获取最近填写草稿信息
    activityList: `${businessHost}case/collect/list`,
    // 病例---活动---列表
    activityDetails: `${businessHost}case/collect/details/`,
    // 病例---活动---详情
    activityConfig: `${businessHost}case/collect/config/get/`,
    // 病例---活动---开关配置
    activityAudiDetails: `${businessHost}case/collect/detailsForAuditor/`,
    // 病例---活动---详情(审核中心)
    activitydetailsTwo: `${businessHost}case/collect/detailsTwo/`,
    // 病例---活动---详情（管理员身份）
    activityExcellent: `${businessHost}case/fill/excellentCases`,
    // 病例---活动---优秀活动
    detail: `${businessHost}case/fill/detail/`,
    // 病例---活动---病例详情
    detailFormFill: `${businessHost}case/fill/detail/forfill/`,
    // 病例---活动---病例详情(采集页)
    enrollCheck: `${businessHost}case/collect/enroll/check/`,
    // 病例---活动---是否需要二次确认
    unPublish: `${businessHost}case/fill/getId`,
    // 病例---采集---创建病例获取ID
    deleteForm: `${businessHost}case/form/fill/content/delete/`,
    // 病例---采集---表单删除
    getFormList: `${businessHost}case/form/entrance/list/`,
    // 病例---采集---获取表单入口
    CASEFormList: `${businessHost}case/collect/formDetail/`,
    // 病例---活动---表单列表
    getUserNotes: `${businessHost}case/collect/user/notes/getUserNotes/`,
    // 病例---是否已经确认用户须知
    save: `${businessHost}case/fill/save`,
    // 病例---采集---病例保存
    saveInfo: `${businessHost}case/fill/saveInfo`,
    // 病例---采集---病例保存信息
    submit: `${businessHost}case/fill/submit`,
    // 病例---采集---病例提交
    formCheck: `${businessHost}case/fill/check/`,
    // 病例---采集---病例必填项校验
    formPreviewData: `${businessHost}case/form/fill/content/get/`,
    // 病例---表单---表单预览(有数据)
    CASEformTabList: `${businessHost}case/form/fill/content/getTabList/`,
    // 病例---表单---表单预览(有数据)
    CASEformData: `${businessHost}case/form/fill/content/audit/get/`,
    // 病例---表单---表单预览(有数据，预览时)
    formPreview: `${businessHost}case/form/preview/`,
    // 病例---表单---表单预览(空表单)
    formSave: `${businessHost}case/form/fill/content/save`,
    // 病例---表单---表单保存
    formDelete: `${businessHost}case/form/fill/content/delete/`,
    // 病例---表单---表单删除
    ImageOcr: `${businessHost}case/attach/ocr/image`,
    // 病例---表单---图片ocr识别
    fileSave: `${businessHost}case/attach/save`,
    // 病例---表单---附件保存/ocr图片识别后提交
    multiplefileSave: `${businessHost}case/attach/save/batch`,
    // 病例---表单---多图片提交
    fileDelete: `${businessHost}case/attach/delete/`,
    // 病例---表单---附件删除
    myCollectList: `${businessHost}case/fill/personal/casecollect/list`,
    // 病例---我的---病例活动列表
    myCaselist: `${businessHost}case/fill/personal/list`,
    // 病例---我的---病例列表
    myCaseDelete: `${businessHost}case/fill/delete/`,
    // 病例---我的---草稿病例删除
    myCaseCount: `${businessHost}case/fill/persional/count`,
    // 病例---我的---活动下当前人病例数统计
    caseAttachGet: `${businessHost}case/attach/get/`,
    // 病例---Excel打开链接预览图片
    checkpre: `${businessHost}case/fill/checkpre`,
    // 病例---表单---病例采集先决条件、
    getEnter: `${businessHost}case/form/inclusion/criteria/list/foruser/`,
    // 病例---表单---获取入排标准、
    saveEnter: `${businessHost}case/fill/inclusion/criteria/save`,
    // 病例---表单---提交入排标准、
    getActivityTags: `${businessHost}case/form/list/tages/`,
    // 病例---表单---获取入活动下所有阶段
    getActivityForm: `${businessHost}case/form/list/`,
    // 病例---表单---获取入活动下所有表单
    Remark: {
      // 病例---点评
      actiList: `${businessHost}case/professor/remark/list/withActivity`,
      // 病例---点评---点评活动列表
      list: `${businessHost}case/professor/remark/list/withCaseFill`,
      // 病例---点评---点评列表（我的点评）
      allList: `${businessHost}case/professor/remark/detail/withCaseFill`,
      // 病例---点评---点评列表（当前病例-我的+其他人的点评列表）
      detail: `${businessHost}case/professor/remark/get/`,
      // 病例---点评---获取点评内容
      aiContent: `${businessHost}case/professor/remark/ai/content`,
      // 病例---点评---获取AI点评内容
      save: `${businessHost}case/professor/remark/save`,
      // 病例---点评---提交评语
      delete: `${businessHost}case/professor/remark/delete/`,
      // 病例---点评---删除点评
      createDef: `${businessHost}case/professor/remark/createDefault/`,
      // 病例---点评---点击分享链接后调用，创建若分配专家
      check: `${businessHost}case/professor/remark/checkAuthority/`,
      // 病例---点评---权限校验
      professorList: `${businessHost}case/professor/list`,
      // 病例---点评---邀请专家列表
      saveInvite: `${businessHost}case/professor/remark/limit/save`,
      // 病例---点评---邀请专
      CASECommentGetTargets: `${businessHost}commentConfig/comment/getTargets` // 我的点评-医学研究-获取点评指标
    },

    caseCollectProvinces: `${businessHost}case/collect/provinces/`,
    // 病例---获取省份列表 get请求/{病例征集活动id}
    caseCollectCities: `${businessHost}case/collect/cities/`,
    // 病例---获取城市列表 get请求/{病例征集活动id}/{所属省份id}
    caseCollectCounties: `${businessHost}case/collect/counties/`,
    // 病例---获取区县列表 get请求/{所属城市id}
    caseCollectCityList: `${businessHost}city/list/`,
    // 病例---获取全部城市列表 get请求/{所属城市id}【 仅Mobile 】
    caseCollectCountyList: `${businessHost}county/list/` // 病例---获取全部区县列表 get请求/{所属城市id}【 仅Mobile 】
  },

  answer: {
    saveApplyInfo: `${djbQuestionHost}question/front/save/apply/info`,
    // 申请加入
    getVideoWatchInfo: `${djbQuestionHost}question/front/getVideoWatchInfo`,
    // 线上答题-综合题-获取用户视频观看时长  GET请求/{综合题compoundQuestionId}
    updateVideoWatchInfo: `${djbQuestionHost}question/front/updateVideoWatchInfo`,
    // 线上答题-综合题-更新用户视频观看时长
    myRecord: `${djbQuestionHost}question/front/myRecord`,
    // 线上答题-答题记录
    resultsSee: `${djbQuestionHost}question/front/answercard`,
    // 线上答题-答题卡
    index: `${djbQuestionHost}question/front/findPCExamPaperList/`,
    // 答题-首页
    indexNtk: `${djbQuestionNtkHost}question/front/findPCExamPaperListNoToken/`,
    // 答题-首页（无token）/{租户id}/{试卷id}
    paperInfo: `${djbQuestionHost}question/front/findExamPaperInfo/`,
    // 答题-试题详情
    info: `${djbQuestionHost}question/manage/findExamPaper/`,
    // 答题-获取试卷详请
    getQuestionBusId: `${djbQuestionHost}question/manage//getQuestionByBusId/`,
    // 线上答题-busid任务接口
    commit: `${djbQuestionHost}question/front/commitExamPaper`,
    // 答题-交卷
    myListt: `${djbQuestionHost}question/front/findMyExamPaperList`,
    // 答题-我的
    analysis: `${djbQuestionHost}question/front/analysis`,
    // 答题解析
    applySave: `${djbQuestionHost}question/front/apply/save`,
    // 答题-申请加入
    applyMyLs: `${djbQuestionHost}question/front/apply/mine`,
    // 答题-我的申请
    getImportUser: `${djbQuestionHost}question/front/apply/userinfo`,
    // 答题-申请-获取提交用户
    examApplyList: `${djbQuestionHost}question/front/listExam/mine`,
    // 申请管理活动列表（第一层）
    applyImportSave: `${djbQuestionHost}question/front/apply/userinfo/commit`,
    // 答题-申请-提交导入用户
    checkIsExists: `${djbQuestionHost}question/front/blackList/checkIsExists/` // 检查是否在黑名单
  },

  competition: {
    index: `${djbQuestionHost}competition/front/examIndex`,
    // 知识竞赛-首页
    indexNtk: `${djbQuestionNtkHost}competition/front/examIndexntk`,
    // 知识竞赛-首页(无KOTEN)
    paper: `${djbQuestionHost}competition/front/answer`,
    // 知识竞赛-试卷信息
    commit: `${djbQuestionHost}competition/front/commit`,
    // 知识竞赛-交卷
    myRecord: `${djbQuestionHost}competition/front/myRecord`,
    // 知识竞赛-我的挑战记录
    ranking: `${djbQuestionHost}competition/front/rankingList`,
    // 知识竞赛-排行榜
    resultsSee: `${djbQuestionHost}competition/front/answercard`,
    // 知识竞赛-错题查看
    myList: `${djbQuestionHost}competition/front/listMyExam`,
    // 知识竞赛-我的答题列表
    team: {
      create: `${djbQuestionHost}competition/front/team/create`,
      // 知识竞赛-战队竞赛-创建
      join: `${djbQuestionHost}competition/front/team/join`,
      // 知识竞赛-战队竞赛-加入
      list: `${djbQuestionHost}competition/front/team/list`,
      // 知识竞赛-战队竞赛-列表
      detail: `${djbQuestionHost}competition/front/team/detail`,
      // 知识竞赛-战队竞赛-详情
      exit: `${djbQuestionHost}competition/front/team/exit`,
      // 知识竞赛-战队竞赛-退出
      fire: `${djbQuestionHost}competition/front/team/fire`,
      // 知识竞赛-战队竞赛-踢人
      dissolution: `${djbQuestionHost}competition/front/team/dissolution` // 知识竞赛-战队竞赛-解散
    },

    taskPost: `${djbQuestionHost}competition/getByBusId/`,
    // 答题竞赛关联任务接口

    getIdentities: `${strategyApiHost}multi-identity/get/MultipleIdentities`,
    // 获取身份列表
    getApplyInfo: `${strategyApiHost}multi-identity/get/apply/info`,
    // 获取申请加入信息

    saveApplyInfo: `${djbQuestionHost}competition/front/save/apply/info`,
    // 申请加入
    competitionApplyList: `${djbQuestionHost}competition/front/listExam`,
    // 申请管理活动列表（第一层）
    competitionApplyMemberList: `${strategyApiHost}multi-identity/apply/manage/list`,
    // 查看申请人员列表（第二层）
    getApplyManageInfo: `${strategyApiHost}multi-identity/apply/manage/info`,
    // 查看人员详情（第三层）
    applyChangeAuditStatus: `${strategyApiHost}multi-identity/apply/manage/change/auditStatus`,
    // 改变审核状态
    competitionFrontapplyList: `${djbQuestionHost}competition/front/apply/list/mine` // 我的申请列表
  },

  // 互动游戏
  interactiveGame: {
    getInfo: `${interactiveGameHost}hd/front/getActivitiesInfo/` // 获取活动详情（带token）
  },

  // 互动病例
  interactiveCase: {
    findExamInfo: `${interactiveCaseHost}case/front/findExamInfo/`,
    // 获取活动详情
    getIcImportUser: `${interactiveCaseHost}case/front/apply/userinfo`,
    // 互动病例-申请-获取导入用户信息
    icApplySave: `${interactiveCaseHost}case/front/apply/save`,
    // 互动病例-申请加入（新增）
    icApplyImportSave: `${interactiveCaseHost}case/front/apply/userinfo/commit`,
    // 互动病例-申请-提交导入用户信息（完善）
    icApplyMyLs: `${interactiveCaseHost}case/front/apply/mine`,
    // 互动病例-我的申请
    icMyList: `${interactiveCaseHost}case/front/findMyExamPaperList`,
    // 互动病例-我参与的互动病例
    saveApplyInfo: `${interactiveCaseHost}case/front/save/apply/info`,
    // 互动病例-申请加入提交
    listCaseMine: `${interactiveCaseHost}case/front/listCase/mine` // 互动病例-申请管理
  },

  // 互动交流
  communication: {
    getMyPostList: `${djbCommunicateHost}personal/posts`,
    // 我的互动交流-我发表的帖子
    getMyCommentList: `${djbCommentHost}comment/front/myList`,
    // 我的互动交流-我评论的
    starPost: `${djbStarApiHost}star/post`,
    // 我的互动交流-帖子点赞
    getPostDetail: `${djbCommunicateHost}post/detail` // 我的互动交流-获取帖子详情
  },

  // 关联活动
  activityRelation: {
    getRelatedActivity: `${strategyApiHost}associatedActivity/getNewActivity`
  },
  // 消息中心2.0
  messageV2: {
    // getMessageList: `${djbNotifyHost}personal/message/list`, // 消息中心-获取消息列表
    // getUnReadCount: `${djbNotifyHost}personal/message/unread/count`, // 消息中心-未读消息数量 get请求
    // markAllRead: `${djbNotifyHost}personal/message/all/read`, // 消息中心-全部已读（需要指定业务号）
    // getUnReadList: `${djbNotifyHost}personal/message/unread/list`, // 消息中心-未读消息列表
    setTop: `${djbNotifyHost}personal/message/top`,
    // 消息中心-置顶
    delete: `${djbNotifyHost}personal/message/delete`,
    // 消息中心-删除
    markRead: `${djbNotifyHost}personal/message/read`,
    // 消息中心-标记已读
    markUnRead: `${djbNotifyHost}personal/message/unread`,
    // 消息中心-标记未读
    messageNotify: `${djbNotifyHost}personal/message/notify`,
    // 消息中心-消息通知（即时推送）
    // 新改的消息 去掉 传 params: 15 参数 之前的接口暂时保留 以防出现问题
    getMessageList: `${djbNotifyHost}personal/message/all/list`,
    // 消息中心-获取消息列表
    getUnReadList: `${djbNotifyHost}personal/message/unread/all/list`,
    // 消息中心-所有未读消息
    getUnReadCount: `${djbNotifyHost}personal/message/unread/all/count`,
    // 消息中心-未读消息数量 get请求
    markAllRead: `${djbNotifyHost}personal/message/all/read` // 消息中心-全部已读
  },

  sign: {
    mySign: `${djbSignHost}contract/manage/myContract`,
    // 合约-我的合约
    myAuditSign: `${djbSignHost}contract/manage/myAuditContract`,
    // 合约-我的合约（审核、驳回）
    myCount: `${djbSignHost}contract/manage/myContractCount`,
    // 合约-我的合约-总数量展示
    preSign: `${djbSignHost}contract/manage/preSign`,
    // 合约-去签约合同详情
    goSign: `${djbSignHost}contract/manage/sign`,
    // 合约-签约
    signView: `${djbSignHost}contract/manage/signView` // 合约-签约
  },

  questionnaire: {
    ctrl: `${qaHost}qa/questionnaire/access/ctrl/`,
    // 问卷调查-首页
    show: `${qaHost}qa/questionnaire/query/`,
    // 问卷调查-首页
    showNtk: `${qaHostNtk}qa/questionnaire/query/`,
    // 问卷调查-首页
    item: `${qaHost}qa/questionnaire/query/items/`,
    // 问卷调查-问卷卷子
    save: `${qaHost}qa/questionnaire/submit`,
    // 问卷调查-提交
    myqaList: `${qaHost}qa/question/my`,
    // 问卷调查-我的-活动列表
    mySubs: `${qaHost}qa/question/my/item/detail`,
    // 问卷调查-我的-我的提交记录
    myDetail: `${qaHost}qa/question/my/option/detail`,
    // 问卷调查-我的-详情
    apply: `${qaHost}qa/questionnaire/user/apply/`,
    // 问卷调查-申请管理 - 申请
    applyList: `${qaHost}qa/user/apply/query`,
    // 问卷调查-申请管理 - 列表
    applyMy: `${qaHost}qa/user/apply/myapply`,
    // 问卷调查-申请管理 - 我的申请
    applyDetail: `${qaHost}qa/user/apply/detail/`,
    // 问卷调查-申请管理 - 申请详情
    applyAudit: `${qaHost}qa/user/apply/audit`,
    // 问卷调查-申请管理 - 审核
    identList: `${qaHost}qa/question/user/title`,
    // 问卷调查-申请管理 - 身份列表
    questionQueryAssociatedActivityGetNewActivity: `${strategyApiHost}associatedActivity/getNewActivity` // 调研问卷-查询是否有下一个活动
  },

  audit: {
    getNextCase: `${businessHost}audit/flowstates/next/`,
    // 当前用户下一个可以审核的任务
    auditList: `${businessHost}audit/flowstates/approvelist`,
    // 审核中心-审核列表
    auditUsersin: `${businessHost}audit/flowstates/usersin`,
    // 审核中心-参与用户
    auditStatistics: `${businessHost}audit/flowstates/statistics/count/`,
    // 审核中心-参与用户-数据统计
    auditApprove: `${businessHost}audit/flowstates/approve`,
    // 审核中心-审核提交
    getFlowStates: `${businessHost}audit/flowstates/get/`,
    // 审核中心-病例详情-获取审核流程状态
    auditWithdraw: `${businessHost}audit/flowstates/withdraw`,
    // 审核中心-撤回
    comment: `${businessHost}audit/flowstates/comment`,
    // 审核中心-评分点评
    userAuditDetail: `${businessHost}v3/audit/condition/current/user/detail`,
    // 获取用户审核条件详情
    caseStoryList: `${caseStoryHost}caseStoryUserAudit/active/list`,
    // 活动列表(审核中心)
    caseStoryChildList: `${caseStoryHost}caseStoryUserAudit/frontAuditPageList`,
    // 活动审核列表(审核中心)
    caseStoryAuditAction: `${caseStoryHost}caseStoryUserAudit/audit`,
    // 数字人审核操作（通过、驳回）
    caseStoryAuditDetails: `${caseStoryHost}caseStoryUserAudit/detail` // 数字人审核操作（通过、驳回）
  },

  pay: {
    getHistory: `${djbpayHost}pay/orders/getFrontPurchaseHistory`,
    // 知识付费-购买记录
    getGoods: `${djbpayHost}pay/item/get/`,
    // 知识付费-商品详情
    payPre: `${djbpayHost}pay/orders/pre`,
    // 知识付费-下单
    orderVerify: `${djbpayHost}/pay/paySettings/company/payVerify/`,
    // 知识付费-根据订单号查询订单支付状态
    payVerify: `${djbpayHost}pay/orders/verify/`,
    // 知识付费-商品支付验证
    getWxAppId: `${djbpayNtkHost}pay/paySettings/getWxAppId`,
    // 知识付费-获取公众号appId
    getOpenId: `${djbpayNtkHost}/pay/wx/getopenid` // 知识付费-通过code获取openId
  },

  common: {
    getWechatShareConfig: `${thirdHost}sig/share/get`,
    // 微信分享配置
    getWechatLoginConfig: `${thirdHost}officialaccountconfig/get`,
    // 微信登录配置获取
    getTags: `${businessHost}search/get/tags`,
    // 前台权限
    wxUnifiedordercode: `${payHost}pay/wxUnifiedordercode`,
    // 用流水号获取二维码支付url
    getresult: `${orderHost}trade/getresult`,
    // 支付通知
    getErwei: `${userHost}config/image/qrcode`,
    // 获取二维码
    gettrade: `${orderHost}trade/gettrade`,
    // 根据金额获取流水号，用于支付
    useraccount: `${orderHost}user/account/`,
    // 个人钱包
    getTaskList: `${businessHost}task/list`,
    // 获取任务列表
    getTaskCondition: `${businessHost}task/condition/`,
    // 获取任务详情
    getTaskAondition: `${businessHost}task/business/`,
    // 功能页获取浏览任务
    inviteUserRelation: `${djbTaskHost}task/other/inviteUserRelation`,
    // 视频&活动邀请关系绑定
    taskRelate: `${djbTaskHost}task/relate`,
    // 功能页获取浏览任务（New）
    taskConditionQuestion: `${djbTaskHost}task/condition/question`,
    // 新任务查看积分详情
    postApiTaskDetail: `${djbTaskHost}task/front/detail`,
    // 签到任务-详情

    taskReceive: `${businessHost}task/receive`,
    // 任务积分领取
    taskReceiveNew: `${djbTaskHost}task/receive`,
    // 任务积分领取（New）
    coinRank: `${djbTaskHost}coin/rank`,
    // 积分排行榜
    imSig: `${vmsHost}sig/get/usersig`,
    // 获取腾讯IM签名
    imIndex: `${vmsHost}im/get/index`,
    // 获取腾讯IM群ID
    imMessageHistory: `${vmsHost}im/message/getImMessageHistory`,
    // 获取IM历史消息
    delUserCache: `${vmsHost}im/message/delUserCache/`,
    // 触发后端 更新用户名缓存
    getService: `${businessHost}business/get`,
    // service
    config: `${userApiHostNtk}company/config/get`,
    login: `${userApiHostNtk}user/login`,
    // 登录
    tokenCache: `${userApiHostNtk}user/token/cache/`,
    // 验证token是否失效
    loginBySms: `${userApiHostNtk}user/loginbycode`,
    // 验证码登录
    getWxOpenId: `${userApiHostNtk}wx/oauth/redirect`,
    // 通过微信code 获取微信openId
    wxloginbycode: `${userApiHostNtk}user/wxloginbycode`,
    // 微信登录 绑定手机号 获取短信验证码
    registToken: `${userApiHostNtk}user/regist/token`,
    // 获取三方免登录token
    sendMessage: `${cmsHost}message/send`,
    messageRead: `${cmsHost}message/isread`,
    getMessage: `${cmsHost}message/get`,
    getMessageList: `${cmsHost}message/getlist`,
    // 我的消息
    logout: `${userApiHost}user/logout`,
    // 退出登录
    regist: `${userApiHostNtk}user/regist`,
    // 注册
    isTokenExist: `${userHost}user/token/is/exist`,
    // 判断token是否过期
    modifyPassword: `${userApiHost}user/modifypassword`,
    // 修改密码
    forgetypassword: `${userApiHostNtk}user/forgetypassword`,
    // 忘记密码
    userIsExist: `${userHost}user/username/exist`,
    updatePhone: `${userHost}user/updatephone`,
    updateUser: `${userHost}user/`,
    getUserInfo: `${userHost}user/`,
    getUserInfoDetail: `${userHost}user/userinfo`,
    getUserInfoById: `${userHost}userinfo/`,
    caseStoryUserinfo: `${userDjbHost}userinfo`,
    gainUserInfo: `${userApiHost}userinfo/get`,
    // 获取用户信息
    userSendCode: `${userDjbHost}sms/user/sendcode`,
    // 获取短信验证码
    sendSmsCode: `${userApiHostNtk}sms/sendcode`,
    // 获取短信验证码
    getCommentInfo: `${djbCommentHost}comment/front/index`,
    // 评论 - 配置信息
    getNtkCommentInfo: `${djbCommentNtkHost}comment/front/ntkIndex`,
    // 评论 - 列表  无需登录
    getCommentList: `${djbCommentHost}comment/front/list`,
    // 评论 - 列表
    getNtkCommentList: `${djbCommentNtkHost}comment/front/list`,
    // 评论 - 列表  无需登录
    getCommentTop: `${djbCommentHost}comment/front/getCommentTop`,
    // 评论 - 置顶评论
    getNtkCommentTop: `${djbCommentNtkHost}comment/front/getCommentTop`,
    // 评论 - 置顶评论  无需登录
    getCommentNotaudit: `${djbCommentHost}comment/front/getnotaudit`,
    // 评论 - 未审核评论
    getCommentDelete: `${djbCommentHost}comment/front/delete`,
    // 评论 - 未审核评论 - 删除
    saveComment: `${djbCommentHost}comment/front/publish`,
    // 评论 - 发表
    checkCommentPermission: `${djbCommentHost}comment/checkCommentPermission`,
    // 评论框显示权限
    commentGenerate: `${djbCommentHost}comment/generate`,
    // AI生成评论
    starComment: `${djbStarApiHost}star/comment`,
    // 评论点赞
    getPoint: `${orderHost}point/`,
    // 获取账户积分
    pointList: `${orderHost}point/detail/list`,
    // 个人积分列表
    isTask: `${businessHost}task/business/`,
    // 验证是否有任务体系
    pointDetail: `${orderHost}point/detail`,
    // 获取积分详情
    accountList: `${orderHost}vp/product/list`,
    // 获取用户账户列表
    getUserAccount: `${orderHost}user/account/`,
    // 获取用户余额
    navigation: `${cmsHost}navigation/list`,
    // 业务类型
    bannerList: `${cmsHost}banner/list`,
    // banner
    newsList: `${cmsHost}news/list`,
    // 新闻列表
    newsNtk: `${djbCMSNtkHost}news/verify`,
    // 新闻详情
    getNavBanner: `${djbCMSNtkHost}company/banner/get/group/by/newNavigationId`,
    // 获取一级导航banner
    getHomeBanner: `${djbCMSNtkHost}company/banner/get/banner/by/newNavigationId?newNavigationId=0`,
    // 获取首页banner
    news: `${djbCMSHost}news/verify`,
    // 新闻详情
    alreadylogin: `${userHost}user/token/exist`,
    // 判断已经在其他地方登录
    friendList: `${cmsHost}friendList/list`,
    // 友情链接列表
    enrollCreate: `${businessHost}enroll/create`,
    // 申请加入
    invitation: `${businessHost}invitation/get/`,
    // 邀请函
    sureHavePower: `${businessHost}enroll/get/`,
    // 判断用户是否有观看权限
    enrollList: `${businessHost}enroll/list`,
    // 申请列表
    enrollMyList: `${businessHost}enroll/myList`,
    // 我的申请
    enrollGet: `${businessHost}enroll/get/`,
    // 申请详情
    enrollReview: `${businessHost}enroll/review`,
    // 审批
    awardHistoryList: `${businessHost}award/history/list`,
    // 百福袋记录
    getContentVerifySetting: `${strategyApiHost}content_verify_setting/get`,
    // 上传证件照配置-获取 GET请求/{contentId}/{contentType}
    saveContentVerifySetting: `${strategyApiHost}content_verify_setting/save`,
    // 上传证件照配置-设置
    ocrCheck: `${userDjbHost}user_certificate/ocr/check`,
    // 上传证件照-证件照ocr检查
    getSelfCertificateInfo: `${userDjbHost}user_certificate/get/self/certificate_info`,
    // 上传证件照-获取证件照
    userCertificateVerify: `${userDjbHost}user_certificate/verify`,
    // 上传证件照-保存信息
    getSelfVerifiedInfo: `${userDjbHost}user_certificate/get/self/verified_info`,
    // 获取申请加入姓名是否可修改
    tag: {
      getContentTag: `${djbStrategyNtkHost}content/label/get` // 内容标签-回显 get请求/{contentId}/{contentType}
    }
  },

  service: {
    uploadVideo: `${vmsHost}vms/getsignature`,
    // 上传视频
    sendRed: `${businessHost}client/award/createAward`,
    // 创建红包
    moneyTo: `${orderHost}vp/product/reward/handle`,
    // 打赏
    createProgram: `${businessHost}manager/program/create`,
    createLive: `${businessHost}manager/live/create`,
    getProgram: [`${businessHost}`, `video/get/`],
    // 管理查看视频
    getLive: [`${businessHost}`, `video/get/`],
    // 管理查看视频
    getMyLiveList: [`${businessHost}`, `video/list`],
    // 培训管理, 视频列表
    getState: [`${businessHost}`, `video/get/state/`],
    // 获取视频状态
    getLiveBackUp: [`${businessHost}`, `video/get/playback/`],
    // 获取视频播放地址
    getuploadurl: `${businessHost}vms/asset/getuploadurl?isDrm=false`,
    uploadImg: `${vmsHost}vms/asset/file`,
    pptList: `${vmsHost}pptUpload/list`,
    // 获取PPT课件
    queryVideoList: [`${businessHost}`, `video/list/play`],
    // 点播列表
    queryLiveList: [`${businessHost}`, `video/list/play`],
    // 直播列表
    getPlayUrl: [`${businessHost}`, `video/get/playback/`],
    getDetail: `${businessHost}client/program/get/`,
    getSurvey: `${businessHost}manager/answers/getQuestionnaire`,
    saveSurvey: `${businessHost}manager/answers/createQuestionnaire`,
    updateSurvey: `${businessHost}manager/answers/updateQuestionnaire`,
    savePaper: `${businessHost}manager/answers/createQuestion`,
    getPaper: `${businessHost}manager/answers/getQuestion`,
    updatePaper: `${businessHost}manager/answers/updateQuestion`,
    relatedPaperSurvey: `${businessHost}manager/answers/createQuestionnaireQuestion`,
    getSurveyInfo: `${businessHost}client/answers/openQuestionnaire`,
    submitSurvey: `${businessHost}client/answers/createAnswerSheets`,
    pushSurvey: `${businessHost}client/answers/pushQuestionnaire`,
    getObsFile: `${businessHost}manager/pushTool/query`,
    // 获取obs配置文件地址
    createBufordBag: `${businessHost}client/award/createAward`,
    // 添加百福袋
    queryAward: `${businessHost}award/source/list`,
    // 查询红包列表
    outAward: `${businessHost}award/receive/`,
    // 领取红包
    answerSourceList: `${businessHost}answer/source/list`,
    // 播放页调查问卷列表列表
    answerOpen: `${businessHost}answer/open/`,
    // 播放页打开调查问卷
    getQuestionnaireDetail: `${businessHost}manager/answers/getQuestionnaire/`,
    answerPush: `${businessHost}answer/push`,
    // 提交调查问卷答案
    receiveUpdate: `${businessHost}answer/receive/update` // 提交物品奖励地址
  },

  video: {
    detailsV2: {
      getMediaKey: `${businessApiHost}media/key/get/MediaKey/`,
      // 获取视频节点
      details: `${businessApiHost}video/details/`,
      // 获取视频详情
      versions: `${businessApiHost}video/pre/`,
      // 通过busID获取 视频详情版本
      videoList: `${businessApiHost}video/programList/get/`,
      // 获取回放列表
      warehouse: `${businessApiHost}video/warehouse/list/get/`,
      // 视频库
      getWarmUpVideo: `${businessApiHost}dataSource/warmUp/get/`,
      // 获取暖场视频
      getMenu: `${strategyApiHost}videoMenuSetting/getAllVideoMenus`,
      // 观看页设置 →菜单设置->获取全部菜单
      getAllVideoAdverts: `${strategyApiHost}advertisingSetting/getAllVideoAdverts`,
      // 获取广告
      getOfficialAccount: `${strategyApiHost}officialAccountsSetting/getOfficialAccount`,
      // 获取创建的二维码
      getRelevanceMenus: `${strategyApiHost}interactSetting/getRelevanceMenus`,
      // 获取关联视频的所有活动
      getRelevance: `${strategyApiHost}interactSetting/allRelevance`,
      // 通过活动类型获取当下的 关联的活动ID
      checkTimeLimit: `${strategyApiHost}interactSetting/check/timeLimit`,
      // 检查是否满足条件
      checkTimeLimit2: `${strategyApiHost}interactSetting/check/timeLimit2`,
      // 检查是否满足条件
      checkRefresh: `${strategyApiHost}interactSetting/check/refresh`,
      // 获取是否需要定时检索
      saveAttendRecord: `${strategyApiHost}interactSetting/save/attendRecord`,
      // 上传强制弹框活动已经完成
      getAnswer: `${djbQuestionHost}question/manage/getByIds`,
      // 查询在线答题接口
      getCompetition: `${djbQuestionHost}competition/getByIds`,
      // 查询竞赛接口
      getQuestionnaireIds: `${qaHost}qa/questionnaire/list/ids`,
      // 查询调研问卷
      getLottery: `${djblotteryHost}lottery/getByIds`,
      // 查询抽奖活动v2
      getVote: `${djbVotingV3}vote/manage/list/ids`,
      // 查询新投票
      getQaV3: `${djbQaV3}qaActiveInfo/frontPageList/ids`,
      // 查询调研问卷3.0
      relatedVideoList: `${strategyApiHost}interactSetting/relatedVideo/pageList` // 获取已关联视频列表
    },

    /*
    * 占河走了以后的第二版视频业务
    * */
    createVideo: `${businessHost}video/source/create`,
    // 创建直播/点播
    updateVideo: `${businessHost}video/source/update`,
    // 修改直播/点播
    getVideoUrl: `${businessApiHost}video/play/back/get/`,
    // 获取视频播放地址 GET请求/{busId}/{videoId}
    getVideoKuUrl: `${djbVmsHost}programplaybackurl/get/`,
    // 获取视频库上传的视频播放地址 GET请求/{extId}
    getVideoInfo: `${businessHost}video/source/get/`,
    // 获取视频信息 GET请求/{busId}
    getVideoInfoNtk: `${businessApiHostNtk}video/getByBusId/`,
    // 无token获取视频信息 GET请求/{busId}
    getVideoList: `${businessHost}video/source/list`,
    // 获取视频列表
    getVideoListNtk: `${businessHost}video/source/ntk/list`,
    // 获取视频列表
    adminGetVideoInfo: `${businessHost}video/source/manager/get/`,
    // 管理者获取视频信息 GET请求/{busId}
    getStatus: `${businessApiHost}video/get/video/basic/`,
    // 获取视频信息 GET请求/{busId}
    adminGetVideoList: `${businessHost}video/source/manager/list`,
    // 管理者获取视频列表
    videoHistoryList: `${businessHost}video/source/history/list`,
    // 线上公开课 观看记录列表
    videoAuthorityList: `${businessApiHost}video/authority/list`,
    // 我的xx列表
    videoAuthorityListNtk: `${businessApiHostNtk}video/authority/list`,
    // 我的xx列表
    watchAdd: `${businessHost}watch/add`,
    // 打点
    labelPoint: `${businessApiHost}label/point`,
    // 标签上报
    labelPointNew: `${strategyApiHost}label/activity/point`,
    // 标签上报-新版
    studyHistory: `${businessHost}video/source/history/list`,
    // 学习记录
    awardGet: `${businessHost}award/get/`,
    // 百福袋详情获取
    awardResult: `${businessHost}award/result/list`,
    // 百福袋领取详情列表
    getPointTime: `${djbStatisticsHost}video/statistics/get/userWatchDuration` // 获取5s打点数据
  },

  videoRoom: {
    /*
    * 互动课堂以后的第三版视频业务
    * */
    getVideoInfo: `${businessApiHost}video/details/`,
    // 获取视频信息 GET请求/{busId}
    getVideoList: `${businessApiHost}video/list`,
    // 获取视频列表
    getAuthorityList: `${businessApiHost}video/authority/list`,
    // 我的xx列表
    getAuthorityListNtk: `${businessApiHostNtk}video/authority/list` // 我的xx列表
  },

  enroll: {
    passwordCheck: `${businessApiHost}join/password/check`,
    // 参与密码校验：{"busId":"课程or活动busId","joinPassword":"参与密码<加密>"}
    getVideoUserEnroll: `${businessApiHost}enroll/`,
    // 获取video用户信息
    updateVideoUserInfo: `${businessApiHost}enroll/update`,
    // 修改video用户信息
    createEnroll2: `${businessHost}v3/enroll/create`,
    // 提交申请
    createEnroll: `${businessApiHost}enroll/create`,
    // 提交申请
    getEnroll: `${businessHost}v3/enroll/get/`,
    // 获取申请 GET请求/{id}
    enrollList: `${businessHost}v3/enroll/list`,
    // 获取我申请的列表
    managerGetEnrollInfo: `${businessHost}v3/enroll/manager/get/`,
    // 管理者获取申请信息 GET请求/{id}
    managerGetEnrollList: `${businessHost}v3/enroll/manager/list`,
    // 管理者获取申请列表
    managerReview: `${businessHost}v3/enroll/manager/review`,
    // 审核通过/审核拒绝
    videoHasPower: `${businessHost}v3/enroll/validate/`,
    // 判断有没有权限
    getProvince: `${businessHost}v3/enroll/province/get/`,
    // 判断课程用户省份信息
    upProvince: `${businessHost}v3/enroll/province/update`,
    // 修改课程用户省份信息
    areaProvince: `${businessHost}province/list`,
    // 获取省份
    areaCity: `${businessHost}city/list`,
    // 获取城市
    areaCounty: `${businessHost}county/list` // 获取区县
  },

  oss: {
    watchAdd: `${businessHost}watch/add`,
    // 打点
    watchGet: `${businessHost}watch/get`,
    // 获取打点
    addIncreaseSet: `${businessHost}oss/manager/addIncreaseSet`,
    // 增加分享 关注 点赞
    addWatchHistory: `${businessHost}oss/manager/addWatchHistory`,
    // 增加观看记录
    listWatchHistory: `${businessHost}oss/manager/listWatchHistory`,
    // 查询观看记录
    timeItem: `${businessHost}oss/manager/watchhistory/last/timeItem`,
    // 查询观看记录
    timeJumpItem: `${businessHost}oss/manager/watchhistory/timeItem` // 查询观看记录
  },

  business: {
    CASEfileDelete: `${businessHost}case/attach/delete/`,
    // 病例---表单---附件删除
    CaseOcrFileDelete: `${businessHost}case/attach/image/delete`,
    // 病例---表单---OCR回填附件删除
    CASEfileSave: `${businessHost}case/attach/save`,
    // 病例---表单---附件保存/ocr图片识别后提交
    createBiLive: [`${businessHost}`, `/video/live/create`],
    // 创建直播
    organization: `${businessHost}organization/query`,
    // 获取药店列表
    program: [`${businessHost}`, `video/program/create`],
    // 创建点播
    videoList: [`${businessHost}`, `video/list/play`],
    // 培训管理, 视频列表
    getInfoLive: [`${businessHost}`, `video/get/`],
    getInfoVideo: [`${businessHost}`, `video/get/`]
  },
  cms: {
    homePageFind: `${djbCMSNtkHost}homePage/find`,
    // 导航管理——首页配置-获取
    navigationGroupList: `${djbCMSNtkHost}navigationGroup/list`,
    // 导航管理——栏目分组列表
    passwordCheck: `${djbCMSNtkHost}news/newsView`,
    // 密码验证
    djbNavList: `${djbCMSNtkHost}navigation/frontList`,
    // 导航菜单
    navigationList: `${djbCMSNtkHost}navigation/navigationList`,
    // 导航菜单（New）
    getChildByParentId: `${djbCMSNtkHost}navigation/getChildByParentId/`,
    // 根据一级导航 获取二级（New）
    getNavigationThird: `${djbCMSNtkHost}navigation/v3/front/third`,
    // 根据二级导航 获取三级（New）
    frontColumnList: `${djbCMSNtkHost}column/frontColumnList`,
    // 首页文章列表（New）
    frontNavigationList: `${djbCMSNtkHost}navigation/frontNavigationList`,
    // 新网站导航
    homepageConf: `${businessHost}cms`,
    // cms首页内容
    homepageConfNew: `${cmsHost}cms/index`,
    // cms首页内容(新二级栏目)
    columnList: `${cmsHost}column/columnlist`,
    // 栏目列表
    getColumn: `${cmsHost}column/getcolumn`,
    // 获取一二级遍标题
    getColumnNew: `${cmsHost}news/column`,
    // 获取栏目的文章
    getColumnNews: `${djbCMSNtkHost}news/column`,
    // 获取栏目的文章（New）
    getTopdataNews: `${djbCMSNtkHost}news/topdata`,
    // 获取栏目的置顶文章
    getParentColumn: `${cmsHost}news/column/parent`,
    // 获取一级文章
    businessNavList: `${cmsHost}navigation/list/`,
    // 病例征集-栏目加载 GET请求/{id} 业务id
    title: {
      // 导航2.0自定义title
      get: `${djbCMSNtkHost}titles/getTitles/1/1`
    }
  },
  relevance: {
    getActivityJump: `${djbQuestionHost}question/front/association/` // 查询活动是否关联活动
  },

  action: {
    getActivity: `${actionHost}action/company/list`,
    // 是否有活动业务
    rankDetail: `${actionHost}active/rank/list`,
    voteDetail: `${actionHost}active/vote/detaillist`,
    voteList: `${actionHost}active/vote/list`,
    voteSourceList: `${actionHost}active/vote/list/`,
    voteTickets: `${actionHost}active/add`
  },
  conference: {
    perfectInfomation: `${businessHost}businessuser/guest/add`,
    // 完善信息
    addSignIn: `${businessApiHost}sign/in/add`,
    // 签到
    addSignUp: `${businessApiHost}sign/up/add`,
    // 报名
    getPlayback: `${businessHost}meeting/room/get/playback/`,
    // 获取会场播放地址
    conferenceSignIn: `${businessHost}meeting/signin/create`,
    // 会议管理, 会议视频列表
    conferenceSingUp: `${businessHost}meeting/signup/create`,
    // 会议管理, 会议视频列表
    conferenceList: `${businessHost}meeting/manager/list`,
    // 会议管理, 会议视频列表
    conferenceRoomList: `${businessHost}meeting/room/list`,
    // 会场列表
    guestList: `${businessHost}meeting/guest/list/`,
    // 嘉宾列表
    getRoom: `${businessHost}meeting/room/get/`,
    // 获取会场
    getConferenceById: `${businessHost}meeting/details/` // 会议详情
  },

  invitation: {
    get: `${djbInvitationHostNtk}invitation/get/` // 动态邀请函获取 id 邀请函ID
  },

  resource: {
    getsignature: `${djbresourceHost}upload/getsignature` // 获取桶签名
    // getSig: `https://30.100api.cn/djb/yb-resource-api-ntk/upload/getsignature`, // 获取腾讯桶签名
  },

  strategy: {
    getContentsList: `${strategyApiHost}contentManager/getContentsList`,
    // 新导航获取内容
    getContentsListNtk: `${djbStrategyNtkHost}contentManager/getContentsList` // 新导航获取内容
  },

  identities: {
    videoIdentitiesList: `${businessApiHost}video/user/config/get/identities/`,
    // 当前课程身份列表
    videoIdentitiesForms: `${businessApiHost}enroll/get/new/`,
    // 获取课程身份对应字段列表及用户信息（默认取租户层用户信息） 课程busId/用户身份id
    videoSubJoin: `${businessApiHost}enroll/join`,
    // 提交 申请加入、报名签到
    videoSubUpdateNew: `${businessApiHost}enroll/update/new`,
    // 提交 学习记录–我的课程信息
    videoIsIdentities: `${businessApiHost}video/user/config/exist/`,
    // 区分有无课程用户配置<可根据此接口返回判断走新还是走旧>
    auditInfo: `${businessApiHost}enroll/get/audit/` // 申请详情 业务类
  },

  classRoomPro: {
    list: `${djbClassProHost}/classProWhiteList/page`,
    // 观众列表
    listDetails: `${djbClassProHost}/classProWhiteList/info`,
    // 观众信息回显
    audit: `${djbClassProHost}/classProWhiteList/audit`,
    // 观众审核
    getAiSummary: `${businessApiHost}video/subtitle/getAiSummary` // 获取会议总结内容
  },

  paperSubmission: {
    activeList: `${paperSubmissionHost}thesisExamine/active/list`,
    // 我的论文-活动列表
    thesisList: `${paperSubmissionHost}thesisExamine/thesis/list`,
    // 我的论文-论文列表
    saveApplyInfo: `${paperSubmissionHost}thesisExamine/save/apply/info`,
    // 申请加入
    examineActivityList: `${paperSubmissionHost}thesisExamine/examine/list`,
    // 审核中心-活动列表
    examineList: `${paperSubmissionHost}thesisExamine/examine/thesis/list`,
    // 审核中心-论文列表
    examineListDetails: `${paperSubmissionHost}thesisExamine/examine/detail`,
    // 审核中心-论文详情
    doExamine: `${paperSubmissionHost}thesisExamine/examine/doExamine`,
    // 审核中心-审核
    myApplyList: `${paperSubmissionHost}thesisApply/myApplyList`,
    // 我的申请
    createdMine: `${paperSubmissionHost}thesisApply/created/mine`,
    // 申请管理列表
    commentActivityList: `${paperSubmissionHost}thesisComment/active/list`,
    //我的点评-论文征集-活动列表
    haveCommentList: `${paperSubmissionHost}thesisComment/thesis/list`,
    //我的点评-论文征集-已点评列表
    noHaveCommentList: `${paperSubmissionHost}thesisComment/comment/thesis/list`,
    //我的点评-论文征集-未点评列表
    getTargets: `${paperSubmissionHost}commentConfig/comment/getTargets/`,
    //我的点评-论文征集-获取点评指标
    getCommentList: `${paperSubmissionHost}thesisComment/comment/list`,
    //我的点评-论文征集-点评列表
    doComment: `${paperSubmissionHost}thesisComment/comment/doComment`,
    //我的点评-论文征集-提交点评
    getComment: `${paperSubmissionHost}thesisComment/comment/getComment/`,
    //我的点评-论文征集-查自己的点评
    getThesisExamine: `${paperSubmissionHost}thesisExamine/duplicate/check/list` //论文征集-查重列表
  },

  // 病历故事
  caseStory: {
    activeDetail: `${caseStoryHost}caseStoryActiveInfo/detail`,
    // 病历故事-活动详情
    activeList: `${caseStoryHost}caseStoryUserSubmit/active/list`,
    // 病历故事-我的活动
    myApplyList: `${caseStoryHost}caseStoryApply/myApplyList`,
    // 病历故事-我的申请
    myCaseStoryList: `${caseStoryHost}caseStoryUserSubmit/frontPageList`,
    // 病历故事-我的创作
    saveApplyInfo: `${caseStoryHost}caseStoryUserSubmit/save/apply/info` // 病历故事-申请加入
  },

  topicPk: {
    myActive: `${topicPkHost}front/topicInfo/participatedPage`,
    //话题pk-我的活动
    myApplyList: `${topicPkHost}external/apply/my`,
    // 我的申请
    applyList: `${topicPkHost}front/topicInfo/page`,
    // 话题pk-申请管理列表
    saveApplyInfo: `${topicPkHost}external/apply/apply` //保存申请加入
  },

  caseDataBase: {
    shareCreate: `${caseDataBase}share/create`
  },
  // 用户画像(活动/注册邀请任务)
  userPortrait: {
    getInviteShortUrl: `${userApiHost}userinfo/getInviteShortUrl`,
    // 获取邀请注册二维码的短连接（登录页）
    inviteTaskList: `${djbTaskHost}task/other/user/list`,
    // 任务中心-任务列表（邀请任务Tab）
    inviteTaskCode: `${djbTaskHost}task/other/inviteShortUrl`,
    // 获取邀请任务二维码
    inviteStatsList: `${djbTaskHost}task/other/inviteUserList`,
    // 邀请统计列表
    inviteActivityNums: `${djbTaskHost}task/other/inviteUserCount`,
    // 邀请活动统计参与人数
    inviteTotal: `${userApiHost}userinfo/inviteUserCount`,
    // 获取用户邀请注册的用户总数
    inviteRegList: `${userApiHost}userinfo/inviteRegList`,
    // 获取用户邀请注册的用户列表
    inviteActivityRecords: `${djbQuestionHost}question/front/inviteUserExamPaperList`,
    // 被邀请用户线上答题活动-参与记录
    inviteWatchRecords: `${businessApiHost}video/watchListByUserId`,
    // 被邀请用户视频观看数据-参与记录
    inviteMedicineRecords: `${businessHost}case/fill/inviteUserCaseCollect/list`,
    // 医学研究-参与记录
    inviteInteractiveRecords: `${interactiveCaseHost}case/front/inviteUserExamPaperList`,
    // 互动病例-参与记录
    inviteSurveyRecords: `${djbQaV3}qaSubmitData/inviteUserJoinData/pageList`,
    // 调研问卷3.0-参与记录
    inviteThesisRecords: `${paperSubmissionHost}thesisExamine/inviteUserActive/list` // 论文征集-参与记录
  }
};