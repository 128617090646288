/* this file is transformed by vux-loader */
/* eslint-disable */
/*
 * Sun-Sky  2023/4/27 下午4:13
 */

const getters = {
  /* message */
  postMsgList: state => state.message.postMsgList,
  showReadBtn: state => state.message.showReadBtn,
  postMsgTotal: state => state.message.postMsgTotal,
  unReadMsgCount: state => state.message.unReadMsgCount,
  /* webSocket */
  stompClient: state => state.socket.stompClient,
  socketUrl: state => state.socket.socketUrl,
  checkInterval: state => state.socket.checkInterval,
  listenerList: state => state.socket.websocket,
  /* base  */
  isLoading: state => state.base.isLoading,
  /* user  */
  bagPmi: state => state.user.bagPmi,
  uploadPmi: state => state.user.uploadPmi,
  livePmi: state => state.user.livePmi,
  surveyPmi: state => state.user.surveyPmi,
  downloadPmi: state => state.user.downloadPmi,
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,
  isUserInfoCompleted: state => state.user.isUserInfoCompleted,
  userId: state => state.user.userId,
  sex: state => state.user.sex,
  phone: state => state.user.phone,
  credentials: state => state.user.credentials,
  avatar: state => state.user.avatar,
  nickname: state => state.user.nickname,
  hosPital: state => state.user.hosPital,
  userTitle: state => state.user.userTitle,
  userOffice: state => state.user.userOffice,
  description: state => state.user.description,
  userName: state => state.user.userName,
  roles: state => state.user.roles,
  messageList: state => state.user.messageList,
  openid: state => state.user.openid,
  /* config 配置 */
  videoPause: state => state.config.videoPause,
  companyConfig: state => state.config.companyConfig,
  // 租户配置信息
  wechatShare: state => state.config.wechatShare,
  // 是否有微信分享
  companyId: state => state.config.companyId,
  isZhenYi: state => state.config.companyId == process.env.VUE_APP_ZHENYI_COMPANY_ID ? true : false,
  // 是否珍医网定制化
  wxConfig: state => state.config.wxConfig,
  // 微信分享配置
  service: state => state.config.service,
  // 业务类型
  isApp: state => state.config.isApp,
  // 是否是在app中显示
  businessTrain: state => state.config.businessTrain,
  businessClass: state => state.config.businessClass,
  businessOnlineOpenClass: state => state.config.businessOnlineOpenClass,
  busAcademicConference: state => state.config.busAcademicConference,
  busInteractionOpenClass: state => state.config.busInteractionOpenClass,
  businteractiveCloudClassroom: state => state.config.businteractiveCloudClassroom,
  busCaseCollection: state => state.config.busCaseCollection,
  activityDrawGame: state => state.config.activityDrawGame,
  activityDrawGameV2: state => state.config.activityDrawGameV2,
  activityExamPaper: state => state.config.activityExamPaper,
  activityCompetition: state => state.config.activityCompetition,
  activityInteractiveCase: state => state.config.activityInteractiveCase,
  activityCommunication: state => state.config.activityCommunication,
  activitySign: state => state.config.activitySign,
  activityQuestionnaire: state => state.config.activityQuestionnaire,
  activityQuestionnaireV3: state => state.config.activityQuestionnaireV3,
  activityCaseVote: state => state.config.activityCaseVote,
  activityVote: state => state.config.activityVote,
  activityVoteV3: state => state.config.activityVoteV3,
  coursewareUpload: state => state.config.coursewareUpload,
  microWebsite: state => state.config.microWebsite,
  isApplyJoin: state => state.config.isApplyJoin,
  websiteTitle: state => state.config.websiteTitle,
  companyLogoUrl: state => state.config.companyLogoUrl,
  companyStatus: state => state.config.companyStatus,
  companyIsTask: state => state.config.companyIsTask,
  companyName: state => state.config.companyName,
  footerDecline: state => state.config.footerDecline,
  footerRecord: state => state.config.footerRecord,
  skinComponent: state => state.config.skinComponent,
  airClass: state => state.config.airClass,
  enterpriseRecommend: state => state.config.enterpriseRecommend,
  obsUrl: state => state.config.obsUrl,
  indexLiveList: state => state.config.indexLiveList,
  indexVisible: state => state.config.indexVisible,
  registerVisible: state => state.config.registerVisible,
  isAppDownload: state => state.config.isAppDownload,
  businessIntegral: state => state.config.businessIntegral,
  businessAll: state => state.config.businessAll,
  // 租户持有的所有业务
  caseTitleShow: state => state.config.caseTitleShow,
  // 病例征集-是否开启自定义标题
  caseTitleValue: state => state.config.caseTitleValue,
  // 病例征集-自定义标题值
  paperTitleValue: state => state.config.paperTitleValue,
  // 论文征集-自定义标题值
  caseStoryTitleValue: state => state.config.caseStoryTitleValue,
  // 病历故事动态title
  questionTitleShow: state => state.config.questionTitleValue,
  // 问卷调查-是否开启自定义标题
  questionTitleValue: state => state.config.questionTitleValue,
  // 问卷调查-自定义标题值
  agreementList: state => state.config.agreementList,
  // 隐私协议列表
  columnAlias: state => state.config.columnAlias,
  // 精彩栏目别名
  newWebsite: state => state.config.newWebsite,
  // 新网站开关
  newActNav: state => state.config.newActNav,
  // 新网站当前nav对象
  coinEncrypt: state => state.config.coinEncrypt,
  // 积分排行榜名字是否脱敏
  enableCompleteInfo: state => state.config.enableCompleteInfo,
  // 是否启用完善信息配置
  inviteCoverImg: state => state.config.inviteCoverImg,
  // 邀请封面图
  /* router 配置 */
  asyncRouter: state => state.rouerConfig.asyncRouter,
  routerFootprint: state => state.rouerConfig.routerFootprint,
  // 路由记录
  /* cms */
  isCmsRequest: state => state.cms.isCmsRequest,
  // 判断是否进行了cms首页请求
  cmsNavigation: state => state.cms.cmsNavigation,
  // 新导航
  newWebNavList: state => state.cms.newWebNavList,
  // 新网站导航
  indexConfig: state => state.cms.indexConfig,
  // 导航-首页配置
  navGroupList: state => state.cms.navGroupList,
  // 导航-分组列表
  bottomNavigationBar: state => state.cms.bottomNavigationBar,
  // 底部动态导航
  cmsNav: state => state.cms.cmsNav,
  // 导航 空中课堂中的
  cmsBanner: state => state.cms.cmsBanner,
  // 轮播图
  navBanner: state => state.cms.navBanner,
  // 轮播图
  cmsColumn: state => state.cms.cmsColumn,
  // 首页栏目
  cmsFriLink: state => state.cms.cmsFriLink,
  // 友情链接
  cmsAdsHome: state => state.cms.cmsAdsHome,
  // 主页的广告
  cmsAdsColumn: state => state.cms.cmsAdsColumn,
  // 栏目页的广告
  cmsAdsDetail: state => state.cms.cmsAdsDetail,
  // 详情页的广告
  cmsColumnNav: state => state.cms.cmsColumnNav,
  relevanceActivity: state => state.video.relevanceActivity,
  // 课程详情关联的2.0版活动
  isidentity: state => state.config.companyConfig.identity,
  // 开通身份管理
  userTitleConfig: state => state.config.companyConfig.userTitle,
  // 是否开通职称选择
  activityVotingComp: state => state.config.activityVotingComp,
  // 投票评比
  paperSubmission: state => state.config.paperSubmission,
  // 论文征集
  caseStory: state => state.config.caseStory,
  // 病历故事
  topicPk: state => state.config.topicPk
};
export default getters;